// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

import "bootstrap"
import "./src/application.scss"
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y"
  })
  flatpickr("[data-behavior='flatpickrdatetime']", {
    altInput: true,
    altFormat: "F j, Y at H:i",
    dateFormat: "Y-m-d H:i",
    enableTime: true
  })
})


import "controllers"
